<template>
    <div class="login">

        <div v-show="showLogin" class="loginFormCss">
            <h1 class="loginTest">管理员登录</h1>
            <el-form :model="loginForm" :rules="loginRules" ref="loginForm">
                <el-form-item  prop="userName">
                    <el-input  prefix-icon="el-icon-user-solid" v-model="loginForm.userName" placeholder="请输入用户名" class="inputLogin" style="margin-top: 5%"></el-input>

                </el-form-item>
                <el-form-item  prop="password">
                    <el-input prefix-icon="el-icon-lock" v-model="loginForm.password" placeholder="请输入密码" type="password" class="inputLogin"></el-input>

                </el-form-item>
                <el-form-item>
                    <!--                            <el-button @click="showLogin=false,showReg=true">注册</el-button>-->
                    <el-button type="primary" style="width: 70%" @click="submitloginForm('loginForm')">登录</el-button>
                </el-form-item>
            </el-form>
        </div>

    </div>
</template>

<script>

    export default {
        name: "login",
        data(){
            return{
                showLogin: true,
                loginForm:{
                    userName:'',
                    password:''
                },
                loginRules:{
                    userName: [
                        { required: true, message: '请输入用户名', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                },
            }
        },
        methods:{
            submitloginForm(formName){
                this.$refs[formName].validate(valid=>{
                    if(valid){
                        this.$http.get('/user/login',{params:{
                                userName:this.loginForm.userName,
                                password:this.loginForm.password,
                                flag:'2'
                            }}).then(res=>{
                            if(res.data.code===200){
                                window.sessionStorage.setItem('user',res.data.data)
                                this.$message.success('欢迎登录')
                                this.$router.push('/home')
                            }
                            else {
                                this.$message.error(res.data.data)
                            }
                        });
                    }
                    else {
                        this.$message.error('表单填写有误，请检查')
                    }
                })
            },
            success(response,file){
                file
                this.regForm.pic=response.data
                this.$refs['regForm'].validateField('pic')
            },
        }
    }
</script>

<style scoped>
    .login
    {
        background: url("../../assets/img/banner.jpg")repeat no-repeat;
        background-size:cover;
        height: 100%;
    }
    .loginFormCss
    {
        position: absolute;
        left: 35%;
        top: 25%;
        text-align: center;
        width: 30%;
        height: 50%;
        background-color: white;
    }
    .regFormCss
    {
        position: absolute;
        left: 35%;
        top: 25%;
        text-align: center;
        width: 30%;
        height: 75%;
        background:url("../../assets/img/mask.png");border-radius:10px;
    }
    .loginTest{
        color: #0A68B1;
        font-size: 20px;
        margin-top: 7%;
        text-align: left;
        margin-left: 5%;
    }
    .inputLogin{
        width: 80%;
        margin-right: 10%;

    }
</style>